import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reissueCard, reissueCardClearState } from 'lib/actions';
import useTranslate from 'lib/languages/useTranslate';
import { CardStatus } from 'lib/type/enums';
import { useForm, usePrevious } from 'lib/utils';
import StatusCardModal from '../presentational/StatusCardModal';
import { getCardsError, getCardsOrderPostSuccess } from 'lib/selectors';
import { hideModal, showModal } from 'actions/modal';
import { DISMISS_MODAL } from 'constants/shared/modalTypes';

const StatusCardModalContainer = ({ cardSerial, account }) => {
    const dispatch = useDispatch();
    const translate = useTranslate('cards.orderReplacementModal');
    const error = useSelector(getCardsError);
    const prevPostError = usePrevious(error);
    const reissueCardIsPostSuccess = useSelector(getCardsOrderPostSuccess);
    const prevPostSuccess = usePrevious(reissueCardIsPostSuccess);

    const options = [
        { value: CardStatus.LOST, label: translate('lost') },
        { value: CardStatus.DAMAGED, label: translate('damaged') },
        { value: CardStatus.STOLEN, label: translate('stolen') },
    ];

    const [form, handleChange] = useForm({
        status: '',
    });

    const handleSubmit = () => {
        dispatch(reissueCard(cardSerial, form.status));
    };

    const handleClose = () => {
        dispatch(reissueCardClearState());
        dispatch(hideModal());
    };

    useEffect(() => {
        if (reissueCardIsPostSuccess && !prevPostSuccess) {
            dispatch(hideModal());
        }
    }, [reissueCardIsPostSuccess, prevPostSuccess, dispatch]);

    useEffect(() => {
        const handleDeleteError = () => {
            if (error) {
                dispatch(
                    showModal({
                        type: DISMISS_MODAL,
                        props: {
                            title: translate('titleText'),
                            message: translate('error'),
                            handleCancel: () => {
                                dispatch(hideModal());
                            },
                        },
                    }),
                );
            }
        };
        if (!prevPostError && error) {
            dispatch(hideModal());
            handleDeleteError();
        }
    }, [error, prevPostError, translate, dispatch]);

    return (
        <div className="status-card-modal">
            <StatusCardModal
                {...form}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                productType={account.productType}
                options={options}
            />
        </div>
    );
};

export default StatusCardModalContainer;
