import { AuthStep } from 'constants/shared/enums';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    LOGIN,
    AUTH_OTP,
    AUTH_TELL_GATEWAY,
    AUTH_ACTIVATION,
    AUTH_ACTIVATION_SUCCESS,
    AUTH_EMAIL,
    AUTH_OPEN_BANKING,
} from '../../../../constants/shared/routes';


const getRouteForStep = (step: AuthStep) => {
    switch (step) {
        case AuthStep.EMAIL_SIGN_IN_FLOW:
        case AuthStep.EMAIL_ACTIVATION_FLOW:
            return AUTH_EMAIL;
        case AuthStep.OTP:
            return AUTH_OTP;
        case AuthStep.TELL_GATEWAY:
            return AUTH_TELL_GATEWAY;
        case AuthStep.ACTIVATION:
            return AUTH_ACTIVATION;
        case AuthStep.ACTIVATION_SUCCESS:
            return AUTH_ACTIVATION_SUCCESS;
        default:
            return LOGIN;
    }
};

export const ActivationGuard = ({ children }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const authStep = localStorage.getItem('authStep') as AuthStep;
    const cognitoToken = localStorage.getItem('cognitoToken');

    const currentStep = getRouteForStep(authStep);
    const allowedRoute = pathname.includes(AUTH_OPEN_BANKING)
    
    useEffect(() => {
        if (allowedRoute) return;
        if (!cognitoToken && !authStep) {
           return history.push(LOGIN);
        }
        history.push(currentStep);
    }, [history, cognitoToken, allowedRoute, currentStep,authStep]);
    
    return children;
};
