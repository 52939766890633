import Typography from 'components/shared/generic/typography/presentational/Typography';
import { useState, useRef, useEffect } from 'react';

import {
    languageCodeOptions,
    languageCodesWords,
} from 'lib/languages/constants';
import languageFlags from 'lib/_content/images/languageFlags';

import withFieldValidation from '../hocs/withFieldValidation';

interface LanguageSelectProps {
    name: string;
    disabled?: boolean;
    value?: string | null;
    onChange: (name: string, value: string) => void;
    showError: () => void;
    iconClass?: string;
    classes?: string;
}

const LanguageSelect = ({
    name,
    disabled = false,
    value = null,
    onChange,
    showError,
    iconClass = '',
    classes = '',
}: LanguageSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const node = useRef() as React.MutableRefObject<HTMLButtonElement>;

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    useEffect(() => {
        if (isOpen && !hasOpened) setHasOpened(true);
        else if (!isOpen && hasOpened) showError();
        // eslint-disable-next-line
    }, [isOpen]);

    if (languageCodeOptions.length <= 1) return null;

    return (
        <button
            className={`language-switcher ${isOpen ? 'is-open' : ''}`}
            onClick={() => !disabled && setIsOpen(!isOpen)}
            ref={node}
        >
            <span className="language-flag-container">
                <img
                    src={value ? languageFlags[value] : languageFlags['eng']}
                    alt={`${value} flag`}
                    className="language-flag"
                />
                <Typography className="text">
                    {languageCodesWords[value]}
                </Typography>
                <div className="selected-box">
                    <i
                        className={`arrow ${
                            iconClass.length ? iconClass : 'fal fa-angle-down'
                        }`}
                    />
                </div>
            </span>
            <div
                className={`language-select select-list size-lg-12 ${
                    disabled ? 'disabled' : ''
                } ${classes}`}
            >
                {isOpen && (
                    <div className="option-selection">
                        <div className="option-container">
                            <button
                                className={`option ${!value ? 'active' : ''}`}
                                onClick={e => handleSelect(e, null)}
                            />

                            {languageCodeOptions.map((opt, i) => (
                                <button
                                    style={{width: "100%"}}
                                    key={`${opt.value} - ${i}`}
                                    className={`option ${
                                        value === opt.value ? 'active' : ''
                                    } ${
                                        !value && opt.value === 'eng'
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={e =>
                                        handleSelect(e, opt.value)
                                    }
                                >
                                    <img
                                        src={languageFlags[opt.value]}
                                        alt="language flag"
                                    />
                                    <p className="label">{opt.label}</p>
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </button>
    );

    function handleClick(e) {
        if (node.current?.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    }

    function handleSelect(e, clicked) {
        e.preventDefault();
        if (value === clicked) return;
        onChange(name, clicked);
    }
};

export default withFieldValidation(LanguageSelect);
