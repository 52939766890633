import ReactCodeInput from 'react-verification-code-input';
import withFieldValidation from '../hocs/withFieldValidation';

interface CodeInputProps {
    name: string;
    placeholder?: string[];
    classes?: string;
    defaultValues?: string[];
    onChange: (name: string, value: string) => void;
    onComplete?: () => void;
    width?: number;
    height?: number;
    fields?: number;
    showError: () => void;
    required?: boolean;
}

const CodeInput = ({
    name,
    placeholder,
    classes,
    defaultValues,
    onChange,
    onComplete = () => {},
    width,
    height,
    fields,
    showError,
    required,
}: CodeInputProps) => {
    const handleChange = value => {
        onChange(name, value);
    };

    const handleComplete = () => {
        onComplete();
        showError();
    };

    return (
        <ReactCodeInput
            placeholder={placeholder}
            className={classes}
            values={defaultValues}
            onChange={handleChange}
            onComplete={handleComplete}
            fieldWidth={width}
            fieldHeight={height}
            fields={fields}
            required={required}
        />
    );
};

export default withFieldValidation(CodeInput);
