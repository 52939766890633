import React from 'react';
import { isEmpty } from 'lib/utils';
import Box from 'components/shared/generic/box/presentational/Box';
import Typography from 'components/shared/generic/typography/presentational/Typography';

interface DataCheckProps {
    children: React.ReactNode;
    isLoading: boolean;
    data?: any;
    error?: string;
    noDataMessage?: string;
    className?: string;
    loadingMessage?: string;
}

const DataCheck = ({
    children,
    data,
    isLoading,
    error,
    noDataMessage = 'There is no data to display.',
    className,
    loadingMessage,
}: DataCheckProps) => {
    if (isLoading) {
        return (
            <Box>
                <Typography className="data-check-loading loading-spinner">
                    <i className="far fa-spinner fa-spin" />
                    {loadingMessage}
                </Typography>
            </Box>
        );
    }

    if (isEmpty(data)) {
        return <Typography className="data-check-no-data">{noDataMessage}</Typography>;
    }

    return (
        <Box className={className}>
            {children}
            {!!error && (
                <Typography className="data-check-error">
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default DataCheck;
