import InputMask from 'react-input-mask';
import withFieldValidation from '../hocs/withFieldValidation';

interface MaskInputProps {
    name: string;
    placeholder?: string;
    classes?: string;
    value: string;
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
    showError: () => void;
    mask?: string;
}

const MaskInput = ({
    name,
    placeholder,
    classes,
    value,
    onChange,
    disabled,
    showError,
    mask,
}: MaskInputProps) => (
    <InputMask
        mask={mask}
        maskChar={null}
        className={classes}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={newVal => {
            onChange(name, newVal.target.value);
        }}
        onBlur={showError}
        disabled={disabled}
    />
);

export default withFieldValidation(MaskInput);
