import { Check } from "../check/presentational/Check";
import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN } from '../../../constants/shared/routes';
import { useCallback, useEffect } from "react";
import { setAuthStep } from "utils/generic";
import { AuthStep } from "constants/shared/enums";

const TellGatewayCheck = () => {
    const history = useHistory();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const consentId = queryParams.get("ConsentId");
    const callBackUrl = queryParams.get("CallbackURL") ?? '';

    const handleRedirect = useCallback(() => {
        setAuthStep(AuthStep.LOGIN);
        history.push(LOGIN);
    }, [history])
    
    useEffect(() => {
        if (!consentId) {
            handleRedirect();
            return;
        }
        sessionStorage.setItem("consentId", consentId);
        sessionStorage.setItem("tellGatewayCallBackUrl", callBackUrl);
        const timeout = setTimeout(() => handleRedirect(), 3000);
        return () => clearTimeout(timeout);
    }, [consentId, callBackUrl, handleRedirect]);

    return <Check />;
};

export default TellGatewayCheck;