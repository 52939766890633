import React from 'react';
import dayjs from 'dayjs';
import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import CurrencyInputField from 'components/shared/form/presentational/CurrencyInput';
import TextInput from 'components/shared/form/presentational/TextInput';
import Select from 'components/shared/form/presentational/Select';
import DatePicker from 'components/shared/form/presentational/DatePicker';
import useTranslate from 'lib/languages/useTranslate';
import { validatePaymentReference, validateCurrency, isEmpty } from 'lib/utils';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const StandingOrderForm = ({
    handleChange,
    handleSubmit,
    isPosting,
    ...form
}) => {
    const translate = useTranslate('standingOrders.form');
    const validationTranslate = useTranslate("validation");
    const recurOptions = [
        { value: '1', label: translate('daily') },
        { value: '2', label: translate('weekly') },
        { value: '3', label: translate('monthly') },
        { value: '4', label: translate('yearly') },
    ];

    const submitDisabled =
        !form.amount ||
        form.amount === '£0.00' ||
        isEmpty(form.reference) ||
        !(form.startOn instanceof Date) ||
        !form.recurType ||
        validatePaymentReference(form.reference, validationTranslate);

    return (
        <Form
            onSubmit={handleSubmit}
            submitText={translate('authorise')}
            isPosting={isPosting}
            disabledButton={submitDisabled}
        >
            <Field required name={translate('amount')}>
                <CurrencyInputField
                    name="amount"
                    placeholder={'£0.00'}
                    classes="generic-input bg"
                    onChange={handleChange}
                    prefix="£"
                    validate={(val) => validateCurrency(val, validationTranslate)}
                />
            </Field>
            <Field name={translate('dateToStart')} required>
                <DatePicker
                    name="startOn"
                    value={form.startOn}
                    placeholderText={translate('dateStart')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    minDate={dayjs().toDate()}
                    maxDate={
                        form.endOn ? dayjs(form.endOn).toDate() : undefined
                    }
                />
            </Field>
            <Field name={translate('dateToEnd')}>
                <DatePicker
                    name="endOn"
                    value={form.endOn}
                    placeholderText={translate('dateEnd')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    minDate={
                        form.startOn
                            ? dayjs(form.startOn).toDate()
                            : dayjs().toDate()
                    }
                />
            </Field>
            <Field required>
                <Select
                    name="recurType"
                    value={form.recurType}
                    placeholder={translate('recurringType')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    options={recurOptions}
                />
            </Field>
            <Field name={translate('reference')} required>
                <TextInput
                    name="reference"
                    value={form.reference}
                    placeholder={translate('reference')}
                    classes="generic-input bg"
                    onChange={handleChange}
                    validate={(val) => validatePaymentReference(val, validationTranslate)}
                />
            </Field>
            <Typography className="warning">
                {translate('warningMessage')}
            </Typography>
        </Form>
    );
};

export default StandingOrderForm;
