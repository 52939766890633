import React from 'react';
import { stringCaptialize } from '../../../../../utils/generic';
import { formatMoney } from 'lib/utils';
import { formatDate } from 'lib/utils/customDate';
import { Transaction, Currency } from 'lib/type';
import clsx from 'clsx';

const PendingTransactionListItem = ({
    item,
    keyIndex,
}: {
    item: Transaction,
    keyIndex: string,
}) => {
    const {
        value,
        currency = Currency.GBP,
        recipient,
        createdDate,
        reference,
    } = item;
    return (
        <div className="transaction-container">
            <input
                id={`collapsible-${keyIndex}`}
                className="toggle"
                type="checkbox"
            />
            <label
                htmlFor={`collapsible-${keyIndex}`}
                className="transaction-toggle"
            >
                <div className="transaction-left">
                    <p className="transaction-name">
                        {recipient
                            ? stringCaptialize(recipient)
                            : 'Transaction'}
                    </p>
                </div>
                <div className="transaction-right">
                    <div className="transaction-item-container">
                        <p className="transaction-type"></p>
                    </div>
                    <div className="transaction-item-container">
                        <p
                            className={clsx('transaction-amount', {
                                green: value > 0,
                            })}
                        >
                            {`${value > 0 ? '+' : ''}${formatMoney(
                                value,
                            )} ${currency}`}
                        </p>
                    </div>
                </div>
            </label>
            <div className="collapsible-content">
                <div className="content-inner">
                    <p>
                        Date - <strong>{formatDate(createdDate)}</strong>
                    </p>
                    {!!reference && (
                        <p>
                            Reference - <strong>{reference}</strong>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PendingTransactionListItem;
