import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';

interface TellGatewayAccountPermissionsProps {
    permissions: string[];
}

const TellGatewayAccountPermissions = ({ permissions }: TellGatewayAccountPermissionsProps) => {
    const translate = useTranslate('consent');
    return (
        <>
            <Typography tag="p" className='consent-permissions-title'>
                <strong>
                    {translate('consentDetails.permissionsRequested')}
                </strong>
            </Typography>
            <div className="consent-permissions">
                {permissions?.map(permission => {
                    return (
                        <Typography tag="span" key={permission}>
                            {permission}
                        </Typography>
                    );
                })}
            </div>
        </>
    );
};

export default TellGatewayAccountPermissions;
