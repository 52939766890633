import BorderBox from "components/shared/generic/borderBox/BorderBox";
import DetailRow from "components/shared/generic/detailRow/DetailRow";
import Typography from "components/shared/generic/typography/presentational/Typography";
import useTranslate from 'lib/languages/useTranslate';
import { ConsentPaymentAccountType, ConsentPaymentInformation, ConsentSchemeName } from "lib/type";

interface PaymentDetailsProps {
    paymentInformation: ConsentPaymentInformation;
    accountType: ConsentPaymentAccountType;
}

const TellGatewayPaymentDetails = ({ paymentInformation, accountType }: PaymentDetailsProps) => {
    const translate = useTranslate('consent');
    const account =
        accountType === ConsentPaymentAccountType.CREDITOR
            ? paymentInformation?.CreditorAccount
            : paymentInformation?.DebtorAccount;

    return (
        <BorderBox className="payment-details">
            <Typography tag="h1">
                {translate('paymentDetails.headerText')}
            </Typography>
            <DetailRow
                label={translate('paymentDetails.identification')}
                value={account?.Identification}
            />
            <DetailRow
                label={translate('paymentDetails.payee')}
                value={account?.Name}
            />
            {account?.SchemeName ===
                ConsentSchemeName.SORT_CODE_ACCOUNT_NUMBER && (
                <>
                    <DetailRow
                        label={translate('paymentDetails.payeeAccount')}
                        value={account?.Identification.substring(
                            6,
                        )}
                    />
                    <DetailRow
                        label={translate('paymentDetails.payeeSortcode')}
                        value={account?.Identification.substring(
                            0,
                            6,
                        )}
                    />
                </>
            )}
            <DetailRow
                label={translate('paymentDetails.amount')}
                value={paymentInformation.InstructedAmount?.Amount}
            />
            <DetailRow
                label={translate('paymentDetails.currency')}
                value={paymentInformation.InstructedAmount?.Currency}
            />
            <DetailRow
                label={translate('paymentDetails.reference')}
                value={paymentInformation.RemittanceInformation?.Reference}
            />
        </BorderBox>
    );
};

export default TellGatewayPaymentDetails;