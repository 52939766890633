import Typography from '../typography/presentational/Typography';

interface DetailRowProps {
    label: string;
    value: string;
    className?: string;
}

const DetailRow = ({ label, value, className }: DetailRowProps) => {
    if (!value) return null;
    return (
        <Typography className={className} tag="p">
            <strong>{label}:</strong> {value}
        </Typography>
    );
};

export default DetailRow;