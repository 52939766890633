import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import DirectDebitItem from '../presentational/DirectDebitItem';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDirectDebits,
    getDirectDebitsError,
    getDirectDebitsIsFetching,
    getDirectDebitsIsPosting,
} from 'lib/selectors';
import { hideModal, showModal } from 'actions/modal';
import { ERROR_MODAL, CONFIRM_MODAL } from 'constants/shared/modalTypes';
import { cancelDirectDebit, fetchDirectDebits } from 'lib/actions';
import { usePrevious } from 'lib/utils';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';

const DirectDebitItemContainer = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const directDebits = useSelector(getDirectDebits);
    const isPosting = useSelector(getDirectDebitsIsPosting);
    const isFetching = useSelector(getDirectDebitsIsFetching);
    const error = useSelector(getDirectDebitsError);

    const prevProps = usePrevious({ isPosting, error });

    useEffect(() => {
        dispatch(fetchDirectDebits());
    }, [dispatch]);

    useEffect(() => {
        if (!isPosting && prevProps.isPosting) {
            if (error) {
                dispatch(showModal({ type: ERROR_MODAL, onClose: dispatch(hideModal()) }));
            } else {
                dispatch(hideModal({ message: error }));
            }
        }
    }, [isPosting, dispatch, error, prevProps.isPosting]);

    const handleSubmitCancel = () => {
       dispatch(cancelDirectDebit(id));
    };
    const showCancelModal = () => {
        dispatch(
            showModal({
                type: CONFIRM_MODAL,
                props: {
                    handleSubmit: handleSubmitCancel,
                    title: 'Cancel Direct Debit',
                    message:
                        'Are you sure you want to cancel this direct debit?',
                },
            }),
        );
    };
    if (!Object.keys(directDebits).length) {
        return <Redirect to="/payments/direct-debit" />;
    }

    const directDebit = directDebits.find(v => v.id === id);
    
    return (
        <DataCheck
            isLoading={isFetching}
            data={directDebit}
            noDataMessage="You currently have no direct debits"
        >
            <DirectDebitItem 
                data={directDebit}
                showCancelModal={showCancelModal}
            />
        </DataCheck>
    );
};

export default DirectDebitItemContainer;
