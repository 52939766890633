import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuterContainer from '../containers/ModalOuterContainer';
import ButtonContainer from 'components/shared/generic/buttonContainer/presentational/ButtonContainer';
import Button from 'components/shared/generic/button/presentational/Button';

interface ErrorModalProps {
    message?: string;
    title?: string;
    closeButtonText?: string;
    onClose: () => void;
}

const ErrorModal = ({
    message = 'An error occurred while processing your request, please try again later.',
    title = 'Error',
    closeButtonText = 'Close',
    onClose,
}: ErrorModalProps) => (
    <ModalOuterContainer className="status-modal" customButtons>
        <div className="description">
            <div className="modal-icon">
                <i className="fa fa-times-circle" />
            </div>
            <Typography tag="h1">{title}</Typography>
            <Typography className="generic-text intro-text">
                {message}
            </Typography>
        </div>
        <ButtonContainer className="center">
            <Button onClick={onClose}>{closeButtonText}</Button>
        </ButtonContainer>
    </ModalOuterContainer>
);

export default ErrorModal;
