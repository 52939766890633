import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { clearPinData } from 'lib/actions';
import { CARDS } from 'constants/shared/routes';
import PinOptions from '../presentational/PinOptions';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import Error from 'components/shared/generic/errorMessage/presentational/Error';

const PinOptionsContainer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!location.state || !location.state.authCVC) {
            history.push(CARDS);
        }

        return () => {
            dispatch(clearPinData());
        };
    }, [dispatch, history, location.state]);

    return (
        <PageContainer className="pin-options-container">
            <DataCheck
                isLoading={!location.state || !location.state.authCVC}
                noDataMessage="Your pin can't be found"
            >
                {location.state.pin ? (
                    <PinOptions pin={`${location.state.pin}`} />
                ) : (
                    <Error
                        message="Could not find pin associated to this card, please contact customer support"
                        backRoute={CARDS}
                    />
                )}
            </DataCheck>
        </PageContainer>
    );
};

export default PinOptionsContainer;
