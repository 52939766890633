import React from 'react';
import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import CurrencyInputField from 'components/shared/form/presentational/CurrencyInput';
import TextInput from 'components/shared/form/presentational/TextInput';
import useTranslate from 'lib/languages/useTranslate';
import { validatePaymentReference, validateCurrency } from 'lib/utils';
import Typography from 'components/shared/generic/typography/presentational/Typography';

const MakePaymentForm = ({
    handleChange,
    handleSubmit,
    isPosting,
    ...props
}) => {
    const translate = useTranslate('makePayment');
    const validationTranslate = useTranslate("validation");
    const isSubmitDisabled =
        !props.amount ||
        !props.paymentReference.length;

    return (
        <Form
            onSubmit={handleSubmit}
            isPosting={isPosting}
            disabledButton={isSubmitDisabled}
        >
            <Field required>
                <CurrencyInputField
                    name="amount"
                    placeholder={'£0.00'}
                    onChange={handleChange}
                    value={props.amount}
                    classes="generic-input bg"
                    prefix="£"
                    validate={(val) => validateCurrency(val, validationTranslate)}
                />
            </Field>
            <Field required>
                <TextInput
                    name="paymentReference"
                    placeholder={translate('form.PaymentReference')}
                    onChange={handleChange}
                    value={props.paymentReference}
                    classes="generic-input bg"
                    validate={(val) => validatePaymentReference(val, validationTranslate)}
                />
            </Field>
            <Typography className="warning">
                {translate('form.warningMessage')}
            </Typography>
        </Form>
    );
};

export default MakePaymentForm;
