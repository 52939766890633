import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { Consent } from 'lib/type';
import DetailRow from 'components/shared/generic/detailRow/DetailRow';

interface TellGatewayConsentDetailsProps {
    consentDetails: Consent;
}

const TellGatewayConsentDetails = ({ consentDetails }: TellGatewayConsentDetailsProps) => {
    const translate = useTranslate('consent');

    const {
        TppName,
        TppWebsite,
        AppName,
        AppDescription,
        Scope,
    } = consentDetails || {};

    return (
        <div className="consent-details">
            <Typography tag="h1">
                {translate('consentDetails.headerText')}
            </Typography>
            <DetailRow label="TPP" value={TppName} />
            <DetailRow label="TPP Website" value={TppWebsite} />
            <DetailRow label="TPP APP" value={AppName} />
            <DetailRow
                label={translate('consentDetails.appDescription')}
                value={AppDescription}
            />
            <DetailRow
                label={translate('consentDetails.consentType')}
                value={Scope}
            />
        </div>
    );
};

export default TellGatewayConsentDetails;
