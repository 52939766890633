import React from 'react';
import useTranslate from 'lib/languages/useTranslate';
import usePendingTransactions from '../hooks/usePendingTransactions';
import SectionTitle from '../../../../shared/generic/sectionTitle/presentational/SectionTitle';
import DataCheck from '../../../../shared/generic/dataCheck/presentational/DataCheck';
import { formatMoney } from 'lib/utils';
import PendingTransactionListItem from './PendingTransactionListItem';
import dayjs from 'dayjs';
import PageContainer from '../../../../shared/generic/pageContainer/presentational/PageContainer';

const PendingTransactionsList = () => {
    const translate = useTranslate('pendingTransactions');

    const {
        isFetching,
        pendingTransactionsAmount,
        pendingTransactions,
    } = usePendingTransactions();

    return (
        <PageContainer className="transactions-container">
            <SectionTitle title={translate('breadcrumbText')} backRoute="/" />

            <DataCheck data={pendingTransactions} isFetching={isFetching}>
                <section className="transactions-scroll-container">
                    <SectionTitle title={translate('titleText')} secondary />

                    <h2 className="amount">{`Amount: ${
                        pendingTransactionsAmount < 0 ? '-' : ''
                    }£${formatMoney(pendingTransactionsAmount).substring(
                        1,
                    )}`}</h2>

                    {pendingTransactions.map((transaction, index) => (
                        <PendingTransactionListItem
                            key={index}
                            item={transaction}
                            keyIndex={`${dayjs(
                                transaction.createdDate,
                            ).format()}-${index}`}
                        />
                    ))}
                </section>
            </DataCheck>
        </PageContainer>
    );
};

export default PendingTransactionsList;
