import { CSSProperties } from "react";

interface FieldProps {
    children: React.ReactNode;
    name?: string;
    sizeClasses?: string;
    classes?: string;
    required?: boolean;
    htmlFor?: string;
    styles?: CSSProperties;
    hideLabel?: boolean;
}
const Field = ({
    children,
    name = '',
    sizeClasses = 'col s12',
    classes = '',
    required = false,
    htmlFor,
    styles = {},
    hideLabel = false,
    ...props
}: FieldProps) => {
    return (
        <div
            className={`form-field ${sizeClasses} ${classes} `}
            style={{ ...styles }}
            {...props}
        >
            {Boolean(name?.length) && !hideLabel && (
                <label className="title" htmlFor={htmlFor}>
                    {name} {required && <sub>*</sub>}
                </label>
            )}
            {children}
        </div>
    );
};

export default Field;
