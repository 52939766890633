import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';
import withFieldValidation from '../hocs/withFieldValidation';

interface DatePickerProps {
    value: any;
    onChange: (name: string, value: any) => void;
    maxDate?: any;
    name: string;
    placeholderText?: string;
    required?: boolean;
    minDate?: any;
    showTimeSelect?: boolean;
    showMonthDropdown?: boolean;
    showYearDropdown?: boolean;
    showError?: () => void;
    classes?: string;
    omitClear?: boolean;
}

const DatePicker = ({
    value,
    onChange,
    maxDate,
    name,
    placeholderText = `Please select ${name}`,
    required = true,
    minDate = dayjs()
        .subtract(100, 'years')
        .toDate(),
    showTimeSelect = false,
    showMonthDropdown = true,
    showYearDropdown = true,
    showError,
    classes,
    omitClear = false,
}: DatePickerProps) => {
    return (
        <div className="date-picker" style={{ position: 'relative' }}>
            <ReactDatePicker
                id={name}
                selected={value}
                maxDate={maxDate}
                onChange={handleChange}
                dateFormat={['dd/MM/yyyy']}
                placeholderText={placeholderText}
                required={required}
                onBlur={handleBlur}
                minDate={minDate}
                showTimeSelect={showTimeSelect}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                dropdownMode="select"
                withPortal
                className={classes}
            />
            <i className="far fa-calendar" aria-hidden="true"></i>
            {!omitClear && !!value && (
                <button onKeyDown={() => handleDateClear()} className="icon-wrapper" onClick={() => handleDateClear()}>
                    <i className="far fa-times-circle" />
                </button>
            )}
        </div>
    );

    function handleChange(date) {
        onChange(name, date);
    }

    function handleBlur() {
        if(showError){
            showError();
        }
    }

    function handleDateClear() {
        onChange(name, '');
    }
};

export default withFieldValidation(DatePicker);
