import React from 'react';
import analytics from 'utils/analytics';
import clsx from 'clsx';

interface SwitchProps {
    name: string;
    value?: boolean;
    children?: React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    tracking?: boolean;
    trackingLabel?: string;
    withText?: boolean;
}

const Switch = ({
    name,
    value,
    children,
    onChange = () => {},
    tracking,
    trackingLabel = 'SWITCH_CLICK',
    withText
}: SwitchProps) => {
    const handleChange = event => {
        onChange(event);
        if (tracking) {
            analytics.sendLinkEvent('SWITCH_CLICK', trackingLabel);
        }
    };

    return (
        <div className="switch-container">
            <label className={clsx('switch', { 'with-text': withText })}>
                <input
                    name={name}
                    checked={value}
                    type="checkbox"
                    onChange={handleChange}
                />
                <span className="slider" />
                {withText && <span className='slider-text'></span>}
            </label>
            <div className="switch-content">{children}</div>
        </div>
    );
};

export default Switch;
