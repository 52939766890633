import React from 'react';

import Field from 'components/shared/form/presentational/Field';
import Form from 'components/shared/form/presentational/Form';
import TextInput from 'components/shared/form/presentational/TextInput';
import Select from 'components/shared/form/presentational/Select';
import useTranslate from 'lib/languages/useTranslate';
import PostCodeAutoComplete from 'components/shared/form/presentational/PostCodeAutoComplete';
import { useDispatch } from 'react-redux';
import { removeFieldError } from 'lib/actions';
import { validatePhone } from 'lib/utils';
import { internationalCodes } from 'lib/constants';

const EditPersonalDetails = ({
    handleChange,
    handleSubmit,
    isPosting,
    error,
    resetData,
    ...props
}) => {
    const translate = useTranslate('personalDetails.form');
    const validationTranslate = useTranslate("validation");
    const {
        email,
        diallingCode,
        mobile,
        buildingNo,
        buildingName,
        streetAddress,
        town,
        postcode,
        country,
    } = props;
    const isSubmitDisabled =
        !email || !mobile || !streetAddress || !town || !postcode || !country;
    const dispatch = useDispatch();
    const _handleChange = (field, value) => {
        if (value.length > 0) {
            dispatch(
                removeFieldError(
                    field === 'buildingName' ? 'buildingNo' : 'buildingName',
                ),
            );
        }
        handleChange(field, value);
    };

    return (
        <>
            <Form
                submitText={translate('save')}
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error ? translate('errorMessage') : ''}
                tracking
                trackingLabel="EDIT_DETAILS_SUBMIT"
                disabledButton={isSubmitDisabled}
            >
                <Field name={translate('email')}>
                    <TextInput
                        name="email"
                        placeholder={translate('email')}
                        value={email}
                        onChange={handleChange}
                        classes="generic-input bg"
                        disabled
                    />
                </Field>
                <Field required>
                    <PostCodeAutoComplete
                        value={postcode}
                        onChange={handleChange}
                        classes="generic-input bg mb"
                        resetData={resetData}
                    />
                </Field>
                <Field name={translate('buildingNo')}>
                    <TextInput
                        name="buildingNo"
                        placeholder={translate('buildingNo')}
                        value={buildingNo}
                        onChange={_handleChange}
                        classes="generic-input bg"
                        hideError
                    />
                </Field>
                <Field name={translate('buildingName')}>
                    <TextInput
                        name="buildingName"
                        placeholder={translate('buildingName')}
                        value={buildingName}
                        onChange={_handleChange}
                        classes="generic-input bg"
                    />
                </Field>
                <Field name={translate('streetAddress')} required>
                    <TextInput
                        name="streetAddress"
                        placeholder={translate('streetAddress')}
                        value={streetAddress}
                        onChange={handleChange}
                        classes="generic-input bg"
                        required
                    />
                </Field>
                <Field name={translate('townCity')} required>
                    <TextInput
                        name="town"
                        placeholder={translate('townCity')}
                        value={town}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                    />
                </Field>
                <Field name={translate('country')}>
                    <TextInput
                        name="country"
                        placeholder={translate('country')}
                        value={country}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                        disabled
                    />
                </Field>
                <Field name={translate('postcode')} required>
                    <TextInput
                        name="postcode"
                        placeholder={translate('postcode')}
                        value={postcode}
                        onChange={handleChange}
                        required
                        classes="generic-input bg"
                    />
                </Field>
                <Field name={translate('diallingCode')}>
                    <Select
                        name="diallingCode"
                        value={diallingCode}
                        onChange={handleChange}
                        placeholder={`${country} (${diallingCode})`}
                        options={internationalCodes}
                        classes="generic-input modal-select"
                    />
                </Field>
                <Field name={translate('mobile')}>
                    <TextInput
                        name="mobile"
                        placeholder={translate('mobile')}
                        value={mobile}
                        onChange={handleChange}
                        classes="generic-input bg"
                        validate={(value) => validatePhone(value, diallingCode, validationTranslate)}
                    />
                </Field>
            </Form>
        </>
    );
};
export default EditPersonalDetails;
