import { combineReducers } from 'redux';
import * as sharedReducers from 'lib/reducers';
import modalReducer from './modal';
import transferWiseQuoteReducer from './tw-quote';
import redirectReducer from './redirect';
import darkModeReducer from './isDarkModeEnabled';
import payeeSlice from 'lib/slices/payeeSlice';
import api from 'lib/services/api';
import { logoutCustomerIOUser } from '../utils/customerIO';
import { logoutSuccess } from 'lib/actions';

const appReducer = combineReducers({
    modalReducer,
    redirectReducer,
    transferWiseQuoteReducer,
    darkModeReducer,
    payeeSlice,
    [api.reducerPath]: api.reducer,
    ...sharedReducers,
});

const rootReducer = (state, action) => {
    if (action.type === logoutSuccess.type) {
        logoutCustomerIOUser();
        const { darkModeReducer } = state;
        state = { darkModeReducer };
    }

    return appReducer(state, action);
};

export default rootReducer;
