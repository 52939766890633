import withFieldValidation from '../hocs/withFieldValidation';
import CurrencyInput from 'react-currency-input-field';

interface CurrencyInputFieldProps {
    name: string;
    placeholder?: string;
    value: string;
    onChange: (name: string, value: string) => void;
    prefix?: string;
    classes?: string;
}

const CurrencyInputField = ({
    name,
    placeholder,
    value,
    onChange,
    prefix,
    classes = '',
}: CurrencyInputFieldProps) => {
    return (
        <CurrencyInput
            decimalSeparator="." 
            groupSeparator=","
            className={`input-currency ${classes}`}
            placeholder={placeholder}
            value={value}
            allowNegativeValue={false}
            prefix={prefix}
            decimalScale={2}
            onValueChange={value => {
                if(value){
                    onChange(name, value);
                }
            }}
        />
    );
};

export default withFieldValidation(CurrencyInputField);
