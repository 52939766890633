import { accountRoles } from 'lib/constants';

import EssentialVerticalCard from '_content/images/cards/SM_Essential.png';
import PremiumVerticalCard from '_content/images/cards/SM_Premium.png';
import PremiumPlusVerticalCard from '_content/images/cards/SM_PremiumPlus.png';

export const passwordLabel = {
    0: 'Weak',
    1: 'Weak',
    2: 'Fair',
    3: 'Good',
    4: 'Strong',
};

export const cardPos = {
    0: 'left',
    1: 'center',
    2: 'right',
};

export const cardImages = {
    [accountRoles.Essential]: EssentialVerticalCard,
    [accountRoles.Premium]: PremiumVerticalCard,
    [accountRoles.PremiumPlus]: PremiumPlusVerticalCard,
};

export const TransactionStatus = {
    incoming_payment_waiting: 'Pending',
};

export const StatementType = {
    CSV: 'csv',
    PDF: 'pdf',
};

export enum AuthStep {
    LOGIN = "LOGIN",
    TELL_GATEWAY = "TELL_GATEWAY",
    EMAIL_SIGN_IN_FLOW = "EMAIL_SIGN_IN_FLOW",
    EMAIL_ACTIVATION_FLOW = "EMAIL_ACTIVATION_FLOW",
    OTP = "OTP",
    ACTIVATION = "ACTIVATION",
    ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS",
}