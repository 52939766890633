import React from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

interface CheckboxProps {
    value?: string;
    checked?: boolean;
    onChange: (name: string, value: boolean) => void;
    name: string;
    text?: string;
    classes?: string;
    disabled?: boolean;
}

const Checkbox = ({
    value = '',
    checked = false,
    onChange,
    name,
    text = '',
    classes = '',
    disabled = false,
}: CheckboxProps) => (
    <div className={`checkbox ${disabled ? 'disabled' : ''} ${classes}`}>
        <label>
            <input
                id={name}
                onChange={newVal => {
                    onChange(name, newVal.target.checked);
                }}
                type="checkbox"
                value={value}
                checked={checked}
                name={name}
                disabled={disabled}
            />
            <span>{!!text.length && <span className="text">{text}</span>}</span>
        </label>
    </div>
);

export default withFieldValidation(Checkbox);
