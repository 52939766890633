import React from 'react';
import { Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import SwitchWith404 from './SwitchWith404';

const SubRouter = ({ base = '/', routes = [] }) => {
    return <SwitchWith404>{routes.map(renderRoute)}</SwitchWith404>;

    function renderRoute({
        component: Component,
        path = '',
        exact = true,
        pageTitle,
    }) {
        // remove duplicate slashes
        const fullPath = `${base}/${path}`
            .replace(/\/\/+/g, '/')
            .toLocaleLowerCase();

        return (
            <Route
                key={path}
                path={fullPath}
                render={props => {
                    return (
                        <>
                            <Helmet>
                                <title>
                                    {pageTitle
                                        ? `${pageTitle} | Suits Me Banking`
                                        : 'Suits Me Banking'}
                                </title>
                            </Helmet>
                            <Component {...props} />
                        </>
                    );
                }}
                exact={exact}
            />
        );
    }
};

export default SubRouter;
