import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';
import {
    REACT_APP_CUSTOMER_IO_API_KEY,
    REACT_APP_CUSTOMER_IO_SITE_ID,
} from 'config';

let analytics: AnalyticsBrowser;

export const initializeCustomerIO = () => {
    try {
        const settings = {
            writeKey: REACT_APP_CUSTOMER_IO_API_KEY,
            cdnURL: 'https://cdp-eu.customer.io',
        };
        const options = {
            integrations: {
                'Customer.io In-App Plugin': {
                    siteId: REACT_APP_CUSTOMER_IO_SITE_ID,
                },
            },
        };

        analytics = AnalyticsBrowser.load(settings, options);
    } catch (error) {
        console.error('Failed to initialize Customer.io analytics:', error);
    }
};

export const identifyCustomerIO = (userId: string) => {
    try {
        analytics?.identify(userId);
    } catch (error) {
        console.error('Failed to identify user in Customer.io:', error);
    }
};

export const trackCustomerIOEvent = (
    eventName: string,
    eventProperties: Record<string, any>,
) => {
    try {
        analytics?.track(eventName, eventProperties);
    } catch (error) {
        console.error('Failed to track event in Customer.io:', error);
    }
};

export const logoutCustomerIOUser = () => {
    try {
        analytics?.reset();
    } catch (error) {
        console.error('Failed to log out user from Customer.io:', error);
    }
};
