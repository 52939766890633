import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAccount,
    getAccountIsFetching,
    getAccountError,
} from 'lib/selectors';
import { allCurrencies } from 'lib/constants';
import { MORE } from 'constants/shared/routes';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import AccountDetails from '../presentational/AccountDetails';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import useTranslate from 'lib/languages/useTranslate';
import Radio from '../../../../shared/form/presentational/Radio';
import {
    fetchCards,
    fetchAccount,
    updateUrlAndVersion,
    fetchFees,
    fetchPendingTransactions,
    fetchActivity,
} from 'lib/actions';
import { getApiVersion } from 'lib/selectors';
import { REACT_APP_ENV } from '../../../../../config';

const AccountDetailsContainer = () => {
    const translate = useTranslate('accountDetails');
    const account = useSelector(getAccount);
    const isFetching = useSelector(getAccountIsFetching);
    const error = useSelector(getAccountError);
    const dispatch = useDispatch();
    const currentApiVersion = useSelector(getApiVersion);

    const isDevelopment = REACT_APP_ENV === 'DEV';
    const options = [
        { text: 'v1', value: '/v1' },
        { text: 'v2', value: '/v2' },
    ];

    const handleChange = value => {
        dispatch(updateUrlAndVersion(value));
        dispatch(fetchCards());
        dispatch(fetchAccount());
        dispatch(fetchFees());
        dispatch(fetchPendingTransactions());
        dispatch(fetchActivity(true));
    };

    const showError = () => {
        console.log('An error occurred');
    };

    return (
        <PageContainer className="more-account-details-container">
            <SectionTitle title={translate('titleText')} backRoute={MORE} />
            <DataCheck
                data={account}
                isLoading={isFetching}
                error={error}
                noDataMessage={translate('noDataMessage')}
            >
                <AccountDetails data={account} flag={getCurrencyFlag()} />
            </DataCheck>

            
            {isDevelopment && (<p style={{ fontSize: 25 }}>Select url version:</p>)}
            {isDevelopment && (
                <Radio
                options={options}
                onChange={handleChange}
                name="urlVersion"
                value={currentApiVersion}
                showError={showError}
            />
            )}
        </PageContainer>
    );

    function getCurrencyFlag() {
        return allCurrencies.filter(
            currency =>
                account.clearedBalanceCurrencyCode === currency.currency_code,
        )[0];
    }
};

export default AccountDetailsContainer;
