import { Link } from 'react-router-dom';
import analytics from 'utils/analytics';
import { springKachingBanner } from 'constants/shared/adverts';
import { hasExpired } from 'lib/utils/customDate';

const ReloadableLink = props => {
    const { isExternal, ...linkProps } = props;
    return isExternal ? (
        <a {...linkProps} href={String(props.to.pathname)}>
            {props.children}
        </a>
    ) : (
        <Link {...linkProps}>{props.children}</Link>
    );
};

const Adverts = ({ banner }) => {
    const isDateExpired = hasExpired('2025-04-20');

    const selectedBanner = !isDateExpired ? springKachingBanner : banner;

    const handleEvent = () =>
        analytics.sendLinkEvent('Adverts Click', selectedBanner.title);

    return (
        <div className="adverts-container">
            <ReloadableLink
                isExternal
                key={selectedBanner.title}
                to={{
                    pathname: selectedBanner.link,
                    state: { backRoute: '/' },
                }}
            >
                <img
                    role="presentation"
                    className="item"
                    src={selectedBanner.background}
                    alt="Suits Me Advert"
                    onKeyDown={handleEvent}
                    onClick={handleEvent}
                />
            </ReloadableLink>
        </div>
    );
};

export default Adverts;
