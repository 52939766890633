import { Link } from 'react-router-dom';
import analytics from 'utils/analytics';

interface FieldLinkProps {
    children: React.ReactNode;
    to?: string;
    href?: string;
    target?: string;
    tracking?: boolean;
    trackingAction?: string;
    className?: string;
}

const FieldLink = ({
    children,
    to,
    href = '',
    target = 'blank',
    tracking = false,
    trackingAction = 'FIELD_LINK_CLICK',
    className = '',
}: FieldLinkProps) => {
    const onClick = () => {
        if (tracking) {
            analytics.sendCTAEvent(trackingAction, href);
        }
    };

    return (
        <div className={`field-link-container ${className}`}>
            {to && (
                <Link to={to} className="field-link">
                    {children}
                </Link>
            )}
            {!to && (
                <a
                    href={href}
                    target={target}
                    onClick={onClick}
                    className="field-link"
                >
                    {children}
                </a>
            )}
        </div>
    );
};

export default FieldLink;
