import LogoWhite from '_content/images/logos/SuitsMe_Logo_Gradient.svg';
import LogoBlack from '_content/images/logos/SuitsMe_Logo_Gradient_White.svg';
import BackgroundTellMoney from '_content/images/tellmoney/tellGateway.svg';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Button from 'components/shared/generic/button/presentational/Button';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import { fetchAccountsList, fetchAccount } from 'lib/actions';
import { getTheme } from 'selectors/darkMode';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import useTranslate from 'lib/languages/useTranslate';
import { getIsFetchingAccounts, getAccount, getAccountIsFetching } from 'lib/selectors';
import { useCreateConsentMutation, useGetConsentQuery } from 'lib/services/tellGateway';
import TellGatewayAccountList from './components/TellGatewayAccountList';
import jwt_decode from 'jwt-decode';
import { ConsentOutcome, ConsentPaymentAccountType, ConsentScope, IAccount, IAccountList } from 'lib/type';
import clsx from 'clsx';
import { hideModal, showModal } from 'actions/modal';
import { ERROR_MODAL } from 'constants/shared/modalTypes';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../../constants/shared/routes';
import { finalizeAuthAndSetToken } from 'utils/auth';
import { setAuthStep } from 'utils/generic';
import TellGatewayAccountPermissions from './components/TellGatewayAccountPermissions';
import TellGatewayPaymentDetails from './components/TellGatewayPaymentDetails';
import TellGatewayConsentDetails from './components/TellGatewayConsentDetails';

export const TellGateway = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const translate = useTranslate('consent');
    const consentId = sessionStorage.getItem('consentId') as string;
    const cognitoToken = localStorage.getItem('cognitoToken') as string;
    const tellGatewayCallBackUrl = sessionStorage.getItem('tellGatewayCallBackUrl') as string;
    
    const isDarkModeEnabled = useSelector(getTheme);
    const account: IAccount = useSelector(getAccount);
    const isAccountListFetching: boolean = useSelector(getIsFetchingAccounts);
    const isAccountFetching: boolean = useSelector(getAccountIsFetching);

    const { data: consent, isLoading: isConsentLoading, error: consentError } = useGetConsentQuery(consentId);
    const [createConsent, { isLoading: isLoadingCreateConsent, error: createConsentError }] = useCreateConsentMutation();

    const [selectedTellAccounts, setSelectedTellAccounts] = useState<IAccountList[]>([]);

    const isLoading = isAccountFetching || isAccountListFetching || isConsentLoading;
    const isButtonDisabled = selectedTellAccounts.length === 0;
    const error = (consentError || createConsentError) as { status: number; data: any } | null;
    const logo = isDarkModeEnabled ? LogoBlack : LogoWhite;

    const handleNavigate = useCallback((url?: string) => {
        if(!url) {
             history.push(LOGIN);
             setAuthStep(null);
             return;
        };
        finalizeAuthAndSetToken(cognitoToken);
        window.location.href = url;
    },[history, cognitoToken]);

    const handleCreateConsent = async (outcome: ConsentOutcome) => {
        const { usernameV1: userId } = jwt_decode(cognitoToken);
        const accountIds = selectedTellAccounts.map(({ accountID }) => accountID);
        const payload = { ConsentId: consentId, Outcome: outcome, UserId: userId, Accounts: accountIds };

        try {
            const response = await createConsent(payload).unwrap();
            handleNavigate(response?.Location);
        } catch (error) {
            console.error('Consent creation error:', error);
        }
    };

    useEffect(() => {
        dispatch(fetchAccountsList());
        dispatch(fetchAccount());
    }, [dispatch]);

    
    useEffect(() => {
        if (error) {
            dispatch(
                showModal({
                    type: ERROR_MODAL,
                    props: {
                        title: translate('errorTitle'),
                        message: error?.data?.Error ?? translate('errorMessage'),
                        closeButtonText: translate('buttonContinue'),
                        onClose: () => {
                            handleNavigate(tellGatewayCallBackUrl);
                            dispatch(hideModal());
                        },
                    },
                }),
            );
        }
    }, [error, dispatch, translate, tellGatewayCallBackUrl, handleNavigate]);

    const { Scope, Permissions, PaymentInformation } = consent || {};

    const isPaymentConsent = Scope === ConsentScope.PAYMENTS;
    const isAccountConsent = Scope === ConsentScope.ACCOUNTS;
    const isFundsConfirmationsConsent = Scope === ConsentScope.FUNDS_CONFIRMATIONS;

    return (
        <DataCheck
            isLoading={isLoading}
            loadingMessage={translate('loadingConsent')}
            className="tell-gateway-container"
            error={error?.data?.message ?? error?.data?.Error}
        >
            <img src={logo} alt="Logo" className="logo mt-4" />
            <img
                src={BackgroundTellMoney}
                alt="BackgroundTellMoney"
                className={clsx('overlay-image', { 'dark-mode': isDarkModeEnabled })}
            />
            <div className="tell-gateway-inner-container">
                <div className="left-container">
                    <div className="header-info">
                        <Typography tag="h1">
                            {translate('headerText', { accountName: account?.accountName })}
                        </Typography>
                        <Typography tag="p" className="header-description">
                            {translate('headerDescription')}
                        </Typography>
                    </div>
                    <TellGatewayAccountList
                        accountName={account?.accountName}
                        setSelectedTellAccounts={setSelectedTellAccounts}
                    />
                </div>
                <div className="right-container">
                    <div className="right-container-inner">
                        <TellGatewayConsentDetails consentDetails={consent} />
                        {isAccountConsent && <TellGatewayAccountPermissions permissions={Permissions} />}
                        {isPaymentConsent && <TellGatewayPaymentDetails accountType={ConsentPaymentAccountType.CREDITOR} paymentInformation={PaymentInformation} />}
                        {isFundsConfirmationsConsent && <TellGatewayPaymentDetails accountType={ConsentPaymentAccountType.DEBTOR} paymentInformation={PaymentInformation} />}
                    </div>
                </div>
            </div>
            <div className="tell-gateway-buttons">
                <Button
                    isPosting={isLoadingCreateConsent}
                    disabled={isButtonDisabled}
                    onClick={() => handleCreateConsent(ConsentOutcome.PASS)}
                >
                    {translate('buttonAccept')}
                </Button>
                <Button
                    isPosting={isLoadingCreateConsent}
                    onClick={() => handleCreateConsent(ConsentOutcome.FAIL)}
                >
                    {translate('buttonReject')}
                </Button>
            </div>
        </DataCheck>
    );
};
