import { useState, useRef, useEffect } from 'react';

import withFieldValidation from '../hocs/withFieldValidation';

interface IOptions {
    label?: string;
    value: string;
    subLabel?: string;
}

interface SelectProps {
    name: string;
    search?: boolean;
    disabled?: boolean;
    value?: string | null;
    options?: IOptions[];
    onChange: (name: string, value: string) => void;
    showError: () => void;
    iconClass?: string;
    placeholder?: string;
    classes?: string;
}

const Select = ({
    name,
    search = false,
    disabled = false,
    value = null,
    options = [],
    onChange,
    showError,
    iconClass = '',
    placeholder = '-- select option --',
    classes = '',
}: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const node = useRef() as React.MutableRefObject<HTMLButtonElement>;
    const filteredOptions = getFilteredOptions();
    const selected = getSelected();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    useEffect(() => {
        if (isOpen && !hasOpened) setHasOpened(true);
        else if (!isOpen && hasOpened) showError();
        // eslint-disable-next-line
    }, [isOpen]);

    return (
        <button
            className={`select-list size-lg-12 ${
                disabled ? 'disabled' : ''
            } ${classes}`}
            ref={node}
            onClick={(e) => {
                e.preventDefault();
                !disabled && setIsOpen(!isOpen)
            }}
        >
            <div className="selected-box">
                {!selected ? (
                    <p className="placeholder">{placeholder}</p>
                ) : (
                    <p className="placeholder">{selected.label}</p>
                )}

                <i
                    className={`arrow ${
                        iconClass.length ? iconClass : 'fal fa-angle-down'
                    }`}
                />
            </div>

            {isOpen && (
                <div className="option-selection">
                    {search && !!options.length && (
                        <button
                            className="search-box"
                            onClick={e => e.stopPropagation()}
                        >
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </button>
                    )}
                    <div className="option-container">
                        {!filteredOptions.length && (
                            <p>There are no options to display</p>
                        )}

                        {filteredOptions.map((opt, i) => (
                            <button
                                key={`${opt.value} - ${i}`}
                                className={`option d-block w-100 text-left ${
                                    value === opt.value ? 'active' : ''
                                }`}
                                onClick={e => handleSelect(e, opt.value)}
                            >
                                <p>{opt.label}</p>
                                {!!opt?.subLabel && (
                                    <p className="sub-label">{opt.subLabel}</p>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </button>
    );

    function handleClick(e) {
        if (node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    }

    function getSelected() {
        return options.find(item => item.value === value);
    }

    function getFilteredOptions() {
        if (!search || !searchTerm) return options;
        return options.filter(opt =>
           opt?.label?.replace(/[^A-Z0-9]/gi, '')
                .toLowerCase()
                .includes(searchTerm.replace(/[^A-Z0-9]/gi, '').toLowerCase()),
        );
    }

    function handleSearchChange(e) {
        e.preventDefault();
        setSearchTerm(e.target.value);
    }

    function handleSelect(e, clicked) {
        e.preventDefault();

        if (value === clicked) return;
        onChange(name, clicked);
    }
};

export default withFieldValidation(Select);
