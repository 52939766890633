import { useSelector } from 'react-redux';
import { CardProvider, IAccountList } from 'lib/type';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import Switch from 'components/shared/generic/switch/presentational/Switch';
import { getAccountsList } from 'lib/selectors';
import useTranslate from 'lib/languages/useTranslate';

interface TellGatewayAccountListProps {
    accountName: string;
    setSelectedTellAccounts: React.Dispatch<React.SetStateAction<IAccountList[]>>;
}

const TellGatewayAccountList = ({ accountName, setSelectedTellAccounts }: TellGatewayAccountListProps) => {
    const translate = useTranslate('consent');
    const accountsList = useSelector(getAccountsList) as IAccountList[];
    const tribeAccounts = accountsList.filter(acc => acc.provider === CardProvider.TRIBE);

    const handleAccountChange = (accountID: string, checked: boolean) => {
        setSelectedTellAccounts(prev =>
            checked
                ? [...prev, tribeAccounts.find(acc => acc.accountID === accountID)!]
                : prev.filter(acc => acc.accountID !== accountID),
        );
    };

    const renderAccountItem = ({ accountID, bankAccountNumber }: IAccountList) => (
        <div key={accountID} className="account-item">
            <div className="account-item-info">
                <Typography tag="h1">
                    {accountName} - {bankAccountNumber}
                </Typography>
                <Typography tag="p">{translate("currentAccount")}</Typography>
            </div>
            <Switch
                name={accountID}
                onChange={e => handleAccountChange(accountID, e.target.checked)}
                withText
            />
        </div>
    );

    return <div className="tell-gateway-accounts">{tribeAccounts.map(renderAccountItem)}</div>;
};

export default TellGatewayAccountList;