import { setJwtHeader } from 'lib/utils';
import { setAuthStep } from './generic';

type UserSignInData = {
    USERNAME: string,
    email: string,
    username: string,
};

export const finalizeAuthAndSetToken = (cognitoToken: string) => {
    localStorage.setItem('accessToken', cognitoToken);
    localStorage.removeItem('cognitoToken');
    localStorage.removeItem('activationStep');
    sessionStorage.removeItem('consentId');
    setJwtHeader(cognitoToken);
    setAuthStep(null);
};

export const getUserInfo = (): UserSignInData | null => {
    const storedUserInfo = localStorage.getItem('userInfo');

    if (storedUserInfo) {
        try {
            const userInfo: UserSignInData = JSON.parse(storedUserInfo);
            return userInfo;
        } catch (error) {
            console.error('Error parsing userInfo from local storage:', error);
        }
    }

    return null;
};
