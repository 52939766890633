interface IProps {
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
  children: React.ReactNode;
  styles?: React.CSSProperties;
}

const Typography = ({
  tag: Tag = "p",
  className = "",
  styles = {},
  children,
  ...props
}: IProps) => {
  return (
    <Tag {...props} className={`sm-typography ${className}`} style={styles}>
      {children}
    </Tag>
  );
};

export default Typography;