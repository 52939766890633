import ReferFriendBanner from 'lib/_content/images/banners/refer-a-friend-banner.svg';
import KachingBanner from 'lib/_content/images/banners/kachingBanner.svg';
import { SUITS_ME_REFER, SUITS_ME_SPRING_KACHING } from 'lib/constants/routes';

export const banner = {
    subtitle: 'Sign up for',
    title: 'Suitsme Rewards',
    background: ReferFriendBanner,
    link: SUITS_ME_REFER,
    isExternal: true,
}

export const springKachingBanner = {
    title: 'Kaching Banner',
    background: KachingBanner,
    link: SUITS_ME_SPRING_KACHING,
    isExternal: true,
};
