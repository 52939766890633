import * as Sentry from '@sentry/react';
import {
    REACT_APP_SENTRY_ENV,
    REACT_APP_SENTRY_DSN,
    REACT_APP_ENV,
} from 'config';
import pckg from '../../package.json';
import { useInitializeSentryUser } from 'lib/hooks';
import { handleSentryError } from 'lib/utils';

export const initSentry = () => {
    const enabledForDev =
        REACT_APP_ENV === 'development' || REACT_APP_ENV === 'staging';

    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN,
        environment: REACT_APP_SENTRY_ENV,
        integrations: [Sentry.browserTracingIntegration()],
        release: pckg.version,
        beforeSend(event) {
            // can filter events in here by returning null.
            return event;
        },
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/yourserver\.io\/api/,
        ],
        enabled: enabledForDev,
    });
};

export const useSetSentryUser = () => {
    const accessToken = localStorage.getItem('accessToken');
    const cognitoToken = localStorage.getItem('cognitoToken');
    const jwtToken = accessToken ?? cognitoToken;
    useInitializeSentryUser(jwtToken, Sentry);
};

export const sentryErrorCb = err => {
    handleSentryError(err, Sentry);
};
