import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import { MORE } from 'constants/shared/routes';
import Button from 'components/shared/generic/button/presentational/Button';
import { useDeleteConsentMutation, useGetConsentsQuery } from 'lib/services/tellGateway';
import { getAccount } from 'lib/selectors';
import { useSelector } from 'react-redux';
import { IAccount } from 'lib/type';
import { getTheme } from 'selectors/darkMode';
import DataCheck from 'components/shared/generic/dataCheck/presentational/DataCheck';
import clsx from 'clsx';

const ConsentManagement = () => {
    const translate = useTranslate('consent');
    const translateLoading = useTranslate('loading');

    const account: IAccount = useSelector(getAccount);
    const isDarkModeEnabled = useSelector(getTheme);

    const { data: consentAccounts, isFetching: isLoadingConsents, error: consentError } = useGetConsentsQuery();
    const [deleteConsent, { isLoading: isLoadingDeleteConsent, error: deleteConsentError }] = useDeleteConsentMutation();

    const error = (consentError || deleteConsentError) as { status: number; data: any } | null;

    const handleConsentDelete = async (consentId: string) => {
        try {
            await deleteConsent(consentId).unwrap();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <PageContainer className="more-account-details-container">
            <SectionTitle title={translate('titleText')} backRoute={MORE} />
            <DataCheck
                data={consentAccounts}
                error={error?.data?.message}
                className="consent-management"
                isLoading={isLoadingConsents}
                loadingMessage={translateLoading('loadingText')}
                noDataMessage={translate('noDataMessage')}
            >
                {consentAccounts?.map(({ ConsentId, ConsentedAccounts: consentAccounts, TppName }) => {
                    return (
                        <div key={ConsentId} className="consent-management-inner">
                            <Typography tag="h1">{TppName}</Typography>
                            <ol className={clsx('account-details consent-item', { 'dark-mode': isDarkModeEnabled })}>
                                {consentAccounts.map(accountID => {
                                    return (
                                        <li key={accountID} className="account-item-info">
                                            {account?.accountName} - {accountID}
                                        </li>
                                    );
                                })}
                            </ol>
                            <Button
                                disabled={isLoadingDeleteConsent}
                                isPosting={isLoadingDeleteConsent}
                                className="consent-button-delete"
                                onClick={() => handleConsentDelete(ConsentId)}
                            >
                                {translate('buttonCancel')}
                            </Button>
                        </div>
                    );
                })}
            </DataCheck>
        </PageContainer>
    );
};

export default ConsentManagement;
