import { useSelector } from 'react-redux';
import { getTheme } from 'selectors/darkMode';
import clsx from 'clsx';

interface BorderBoxProps {
    children: React.ReactNode;
    className?: string;
}
const BorderBox = ({ children, className }: BorderBoxProps) => {
    const isDarkMode = useSelector(getTheme);

    return (
        <div className={clsx(`border-box ${className}`, { 'dark-mode': isDarkMode })}>{children}</div>
    );
};

export default BorderBox;