import React from 'react';
import PersonalDetailsIcon from '_content/images/icons/more/personal-details.png';
import PersonalDetailsIconDark from '_content/images/icons/more/personal-details-dark.png';
import AccountDetailsIcon from '_content/images/icons/more/account-details.png';
import AccountDetailsIconDark from '_content/images/icons/more/account-details-dark.png';
import ConsentImage from '_content/images/icons/sm-bullet.svg';
import ConsentImageDark from '_content/images/icons/sm-bullet-darkmode.svg';
import ManageSubscriptionsIcon from '_content/images/icons/more/manage-subscriptions.png';
import ManageSubscriptionsIconDark from '_content/images/icons/more/manage-subscriptions-dark.png';
import TermsConditionsIcon from '_content/images/icons/more/terms-and-conditions.png';
import TermsConditionsIconDark from '_content/images/icons/more/terms-and-conditions-dark.png';
import ContactUsIcon from '_content/images/icons/more/contact-us.png';
import InstagramIcon from '_content/images/icons/more/instagram.png';
import LinkedInIcon from '_content/images/icons/more/linkedin.png';
import PinterestIcon from '_content/images/icons/more/pinterest.png';
import TiktokIcon from '_content/images/icons/more/tiktok.png';
import YoutubeIcon from '_content/images/icons/more/youtube.png';
import ContactUsIconDark from '_content/images/icons/more/contact-us-dark.png';
import CommunicationsIcon from '_content/images/icons/more/communications.png';
import CommunicationsIconDark from '_content/images/icons/more/communications-dark.png';
import ReferFriendIcon from '_content/images/icons/more/refer-friend.png';
import ReferFriendIconDark from '_content/images/icons/more/refer-friend-dark.png';
import FollowUsIcon from '_content/images/icons/more/follow-us.png';
import LikeUsIcon from '_content/images/icons/more/facebook.png';
import DiscountsIcon from '_content/images/icons/more/discounts.png';
import DiscountsIconDark from '_content/images/icons/more/discounts-dark.png';
import SettingsIcon from '_content/images/icons/more/app-settings.png';
import SettingsIconDark from '_content/images/icons/more/app-settings-dark.png';
import {
    MORE_ACCOUNT_DETAILS,
    MORE_CONSENT_MANAGEMENT,
    MORE_MANAGE_SUBSCRIPTION,
    MORE_PERSONAL_DETAILS,
    MORE_COMMUNICATION_PREFERENCES,
    MORE_SETTINGS,
    MORE_REFER_A_FRIEND,
} from 'constants/shared/routes';
import {
    SUITS_ME_TWITTER,
    SUITS_ME_FACEBOOK,
    SUITS_ME_INSTAGRAM,
    SUITS_ME_CONTACT_US,
    SUITS_ME_TERMS_AND_CONDITIONS,
    SUITS_ME_PRIVACY_POLICY,
    ACCOUNT_HOLDER_DISCOUNTS,
    SUITS_ME_LINKEDIN,
    SUITS_ME_PINTEREST,
    SUITS_ME_TIKTOK,
    SUITS_ME_YOUTUBE,
} from 'lib/constants/routes';
import SectionTitle from 'components/shared/generic/sectionTitle/presentational/SectionTitle';
import MenuItem from 'components/shared/generic/menuItem/presentational/MenuItem';
import PageContainer from 'components/shared/generic/pageContainer/presentational/PageContainer';
import GridContainer from 'components/shared/generic/gridContainer/presentational/GridContainer';
import Button from 'components/shared/generic/button/presentational/Button';
import useTranslate from 'lib/languages/useTranslate';
import PropTypes from 'prop-types';

const More = ({ logoutClick, isDarkModeEnabled, handleShowExportModal }) => {
    const translate = useTranslate('more');
    return (
        <PageContainer className="more-container">
            <SectionTitle title={translate('profile')} />

            <GridContainer col="2" gap="32" className="more-menu-container">
                <div>
                    <div className="more-title">
                        <SectionTitle title={translate('options')} secondary />
                    </div>
                    <div className="menu-container">
                        <MenuItem
                            title={translate('profileList.personalDetails')}
                            to={MORE_PERSONAL_DETAILS}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? PersonalDetailsIconDark
                                            : PersonalDetailsIcon
                                    }
                                    alt="Personal Details Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('profileList.accountDetails')}
                            to={MORE_ACCOUNT_DETAILS}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? AccountDetailsIconDark
                                            : AccountDetailsIcon
                                    }
                                    alt="Account Details Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('profileList.consentManagement')}
                            to={MORE_CONSENT_MANAGEMENT}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? ConsentImageDark
                                            : ConsentImage
                                    }
                                    alt="Consent Management Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('profileList.myPricePlan')}
                            to={MORE_MANAGE_SUBSCRIPTION}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? ManageSubscriptionsIconDark
                                            : ManageSubscriptionsIcon
                                    }
                                    alt="Manage Subscription Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('profileList.contactUs')}
                            href={SUITS_ME_CONTACT_US}
                            target="blank"
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? ContactUsIconDark
                                            : ContactUsIcon
                                    }
                                    alt="Contact Us Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('profileList.discounts')}
                            target="_blank"
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? DiscountsIconDark
                                            : DiscountsIcon
                                    }
                                    alt="Discounts Icon"
                                />
                            }
                            href={ACCOUNT_HOLDER_DISCOUNTS}
                            tracking
                            trackingLabel="DISCOUNTS_CLICK"
                        />
                        <MenuItem
                            title={translate(
                                'securityAndSettingsList.commsPreference',
                            )}
                            to={MORE_COMMUNICATION_PREFERENCES}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? CommunicationsIconDark
                                            : CommunicationsIcon
                                    }
                                    alt="Communications Preferences Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('settings')}
                            to={MORE_SETTINGS}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? SettingsIconDark
                                            : SettingsIcon
                                    }
                                    alt="Fees Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('exportStatement')}
                            onClick={handleShowExportModal}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? TermsConditionsIconDark
                                            : TermsConditionsIcon
                                    }
                                    alt="Fees Icon"
                                />
                            }
                        />
                    </div>
                    <Button className="logout" onClick={logoutClick}>
                        {translate('logout')}
                    </Button>
                </div>
                <div>
                    <div className="more-title">
                        <SectionTitle title={translate('aboutUs')} secondary />
                    </div>
                    <div className="menu-container">
                        <MenuItem
                            title={translate('aboutUsList.referAFriend')}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? ReferFriendIconDark
                                            : ReferFriendIcon
                                    }
                                    alt="Refer a friend Icon"
                                />
                            }
                            to={MORE_REFER_A_FRIEND}
                            tracking
                            trackingLabel="REFER_A_FRIEND_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.twitter')}
                            target="_blank"
                            icon={
                                <img
                                    src={FollowUsIcon}
                                    alt="Follow us on Twitter Icon"
                                />
                            }
                            href={SUITS_ME_TWITTER}
                            tracking
                            trackingLabel="FOLLOW_US_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.instagram')}
                            target="_blank"
                            icon={
                                <img
                                    src={InstagramIcon}
                                    alt="Follow us on Instagram Icon"
                                />
                            }
                            href={SUITS_ME_INSTAGRAM}
                            trackingLabel="FOLLOW_US_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.linkedIn')}
                            target="_blank"
                            icon={
                                <img
                                    src={LinkedInIcon}
                                    alt="Follow us on LinkedIn Icon"
                                />
                            }
                            href={SUITS_ME_LINKEDIN}
                            trackingLabel="FOLLOW_US_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.pinterest')}
                            target="_blank"
                            icon={
                                <img
                                    src={PinterestIcon}
                                    alt="Follow us on Pinterest Icon"
                                />
                            }
                            href={SUITS_ME_PINTEREST}
                            trackingLabel="FOLLOW_US_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.tiktok')}
                            target="_blank"
                            icon={
                                <img
                                    src={TiktokIcon}
                                    alt="Follow us on Tiktok Icon"
                                />
                            }
                            href={SUITS_ME_TIKTOK}
                            trackingLabel="FOLLOW_US_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.facebook')}
                            target="_blank"
                            icon={
                                <img
                                    src={LikeUsIcon}
                                    alt="Like us on Facebook Icon"
                                />
                            }
                            href={SUITS_ME_FACEBOOK}
                            tracking
                            trackingLabel="LIKE_US_ON_FACEBOOK_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.youtube')}
                            target="_blank"
                            icon={
                                <img
                                    src={YoutubeIcon}
                                    alt="Like us on Youtube Icon"
                                />
                            }
                            href={SUITS_ME_YOUTUBE}
                            trackingLabel="LIKE_US_ON_YOUTUBE_CLICK"
                        />
                        <MenuItem
                            title={translate('aboutUsList.tsAndCs')}
                            target="_blank"
                            href={SUITS_ME_TERMS_AND_CONDITIONS}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? TermsConditionsIconDark
                                            : TermsConditionsIcon
                                    }
                                    alt="Terms and Conditions Icon"
                                />
                            }
                        />
                        <MenuItem
                            title={translate('aboutUsList.privacyPolicy')}
                            target="_blank"
                            href={SUITS_ME_PRIVACY_POLICY}
                            icon={
                                <img
                                    src={
                                        isDarkModeEnabled
                                            ? TermsConditionsIconDark
                                            : TermsConditionsIcon
                                    }
                                    alt="Privacy Policy Icon"
                                />
                            }
                        />
                    </div>
                </div>
            </GridContainer>
        </PageContainer>
    );
};

More.propTypes = {
    logoutClick: PropTypes.func.isRequired,
    isDarkModeEnabled: PropTypes.bool,
    handleShowExportModal: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default More;
