import { useLayoutEffect } from 'react';
import withFieldValidation from '../hocs/withFieldValidation';

interface PostCodeAutoCompleteProps {
    classes?: string;
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
}

const PostCodeAutoCompleteInput = ({ classes, onChange, disabled }: PostCodeAutoCompleteProps) => {

    useLayoutEffect(() => {
        if ((window as any).pca) (window as any).pca.load();
    }, []);

    const updateValues = e => {
        e.preventDefault();
        const fields = [
            'buildingNo',
            'buildingName',
            'streetAddress',
            'town',
            'county',
            'postcode',
            'country',
        ];

        fields.forEach(name => {
            (document.getElementsByName(name) as NodeListOf<HTMLInputElement>).forEach(({ value }) => {
                onChange(name, value);
            });
        });
    };

    return (
        <div className={`sm-input postcode ${disabled && 'disabled'}`}>
            <LoqateForm
                updateValue={updateValues}
                disabled={disabled}
                classes={classes}
            />
        </div>
    );
};

const LoqateForm = ({ disabled, classes, updateValue }) => (
    <div>
        <div className="input-container">
            <i className="auto-complete-icon sm-input-prepend-icon fas fa-search fa-2x"></i>
            <input
                name="addressSearch"
                placeholder="Search postcode or address"
                className={`${classes} location-search-input`}
                disabled={disabled}
                onBlur={updateValue}
            />
        </div>
    </div>
);

export default withFieldValidation(PostCodeAutoCompleteInput);
